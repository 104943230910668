import Instagram from "components/Icon/Instagram";
import Twitter from "components/Icon/Twitter";

const Footer = () => {
  return (
    <>
      <div className="h-[2px] bg-gradient-to-r from-[#1D1D1D] to-[#B441B8]"></div>
      <div className="py-[60px] flex items-center px-[20px] md:px-[225px]">
        <div className="w-full">
          <div className="flex gap-[38px] justify-center md:justify-end items-center mb-[60px] md:mb-[40px]">
            <a href="https://www.instagram.com/incognitomgt/" target="_blank">
              <Instagram
                className="fill-white hover:fill-[#a318a8]"
                width="36"
                height="36"
              />
            </a>
            {/* <img className="h-[36px]" src="/assets/svg/social/youtube.svg" /> */}
            <a href="https://twitter.com/Incognitomgt" target="_blank">
              <Twitter
                className="fill-white hover:fill-[#a318a8]"
                width="36"
                height="36"
              />
            </a>
          </div>
          <div className="flex md:flex-row flex-col-reverse gap-[60px] md:gap-0 justify-between items-center">
            <p className="opacity-50">
              © 2023 Incognito mgt. All rights reserved
            </p>
            <p>
              <span className="opacity-50 mr-[16px]">General Enquires :</span>
              hello@incognitomgt.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
