const Subitem = ({
  heading,
  content,
}: {
  heading: string;
  content: string;
}) => {
  return (
    <>
      <div className="flex flex-col gap-[12px]">
        <p className="text-[24px] font-medium">{heading}</p>
        <hr className="border-b border-[#A318A8]" />
        <p className="leading-[19px]">{content}</p>
      </div>
    </>
  );
};

export default Subitem;
