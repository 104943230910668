const Instagram = ({
  className,
  width = "24",
  height = "24",
}: {
  className: string;
  width?: string;
  height?: string;
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 35.98 35.98"
        className={className}
      >
        <g id="Group_24" data-name="Group 24">
          <path
            id="Path_11"
            data-name="Path 11"
            d="M517.99,160c-4.886,0-5.5.021-7.417.108a13.219,13.219,0,0,0-4.367.837,9.2,9.2,0,0,0-5.261,5.261,13.218,13.218,0,0,0-.837,4.367c-.087,1.919-.108,2.531-.108,7.417s.021,5.5.108,7.417a13.218,13.218,0,0,0,.837,4.367,9.2,9.2,0,0,0,5.261,5.261,13.219,13.219,0,0,0,4.367.837c1.919.087,2.531.108,7.417.108s5.5-.021,7.417-.108a13.219,13.219,0,0,0,4.367-.837,9.2,9.2,0,0,0,5.261-5.261,13.218,13.218,0,0,0,.837-4.367c.087-1.919.108-2.531.108-7.417s-.021-5.5-.108-7.417a13.218,13.218,0,0,0-.837-4.367,9.2,9.2,0,0,0-5.261-5.261,13.219,13.219,0,0,0-4.367-.837C523.489,160.021,522.876,160,517.99,160Zm0,3.241c4.8,0,5.372.019,7.27.1a9.953,9.953,0,0,1,3.341.62,5.957,5.957,0,0,1,3.414,3.414,9.952,9.952,0,0,1,.62,3.341c.086,1.9.1,2.466.1,7.27s-.019,5.372-.1,7.27a9.952,9.952,0,0,1-.62,3.341,5.957,5.957,0,0,1-3.414,3.414,9.953,9.953,0,0,1-3.341.62c-1.9.086-2.465.1-7.27.1s-5.373-.019-7.27-.1a9.954,9.954,0,0,1-3.341-.62,5.957,5.957,0,0,1-3.414-3.414,9.954,9.954,0,0,1-.62-3.341c-.086-1.9-.1-2.466-.1-7.27s.019-5.372.1-7.27a9.954,9.954,0,0,1,.62-3.341,5.957,5.957,0,0,1,3.414-3.414,9.954,9.954,0,0,1,3.341-.62C512.618,163.26,513.187,163.241,517.99,163.241Zm0,5.511a9.238,9.238,0,1,0,9.238,9.238A9.237,9.237,0,0,0,517.99,168.752Zm0,15.235a6,6,0,1,1,6-6A6,6,0,0,1,517.99,183.987Zm11.762-15.6a2.159,2.159,0,1,1-2.159-2.159A2.159,2.159,0,0,1,529.752,168.387Z"
            transform="translate(-500 -160)"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export default Instagram;
