import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="">
        <Header />
        <div className="max-w-[390px] md:max-w-[1920px] mx-auto overflow-x-hidden">
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
