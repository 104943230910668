import useMousePosition from "hooks/useMousePosition";

const CustomCursor = () => {
  const { x, y } = useMousePosition();

  return (
    <div
      className="w-[15px] h-[15px] bg-[#A318A8] rounded-full z-[999] fixed top-1/2 left-1/2 pointer-events-none hidden md:block"
      style={{ left: `${x && x - 7.5}px`, top: `${y && y - 7.5}px` }}
    ></div>
  );
};

export default CustomCursor;
