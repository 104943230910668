import React, { useState, useEffect, useRef, lazy } from "react";

interface DropdownProps {
  children: React.ReactNode;
  label: string;
  className?: string;
}

// const useOutsideAlerter = (ref: any, close: any) => {
//   useEffect(() => {
//     function handleClickOutside(event: any) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         close(false);
//       }
//     }
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [ref, close]);
// };

const Dropdown = ({ children, label, className }: DropdownProps) => {
  const [show, setShow] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, setShow);

  const toggle = () => {
    setShow(!show);
  };

  return (
    <div
      className="font-bold relative mt-[40px]"
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <button className="tracking-[2.5px] font-medium hover:text-[#A318A8]">
        {label}
      </button>
      {
        <div
          className={`duration-300 -z-10 ${
            show ? `translate-y-full opacity-100` : `translate-y-1/2 opacity-0`
          }`}
          onMouseOver={() => setShow(true)}
        >
          {children}
        </div>
      }
    </div>
  );
};

export default Dropdown;
