import React, { useEffect, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCards, Autoplay } from "swiper/modules";
import Aos from "aos";

import { useScroll } from "hooks/useScroll";
import useWindowSize from "hooks/useWindowSize";

import ServiceCard from "components/ServiceCard/ServiceCard";
import ReviewCard from "components/ReviewCard";
import CustomCursor from "components/CustomCursor";

import { BREAKPOINT } from "config";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  const { width, height } = useWindowSize();
  const isMobile = useMemo(() => (width > BREAKPOINT ? false : true), [width]);

  const reviews = [
    {
      name: "Emily",
      position: "Student",
      review:
        "Hi, I'm Emily, and I owe my modelling success to Incognito. When I started, I had a tiny following and zero industry knowledge. Incognito transformed my career and social media game. If you're passionate but unsure, give Incognito a shot.",
      avatar: "/assets/img/Emily.png",
    },
    {
      name: "Kaitlyn",
      position: "Model",
      review:
        "Hey, As a traditional model, I faced challenges finding gigs, but when I explored OnlyFans, Incognito provided invaluable support. They made the transition smoother, and they've been there every step of the way. If you're considering a similar move, I'd recommend giving Incognito a try. They can certainly make the process easier and more manageable!",
      avatar: "/assets/img/Kaitlyn.png",
    },
    {
      name: "Natalia",
      position: "Student Nurse",
      review:
        "Highly recommend!! Hi, I'm Natalia, a Student Nurse from Ukraine. Relocating to the UK was daunting, but this management team made it so much easier. They helped me balance work, studies and helped me with the language issues. More than just managers, they've been a lifeline. ",
      avatar: "/assets/img/Natalia.png",
    },
    {
      name: "Imogen",
      position: "Waitress",
      review:
        "Hi, The management here has been a big help. They nailed a flexible schedule for me, which is awesome. Had a little hiccup with a double-booked meeting once, but they sorted it quickly and made sure it didn't happen again. Great team, good vibes. Recommend them for sure!",
      avatar: "/assets/img/Imogen.png",
    },
  ];

  const { scrollY } = useScroll();

  return (
    <>
      <CustomCursor />
      <div className="md:pt-[135px] pt-[60px] px-[16px] md:px-[80px] 2xl:px-[225px] mt-[100px] md:mt-[140px]">
        <div
          className="fixed bottom-0 left-1/2 -translate-x-1/2 px-[16px] w-full max-w-[390px] mx-auto"
          style={{
            opacity: `${100 - 0.3 * scrollY}%`,
          }}
          onClick={() => {
            width < 768 && window.scrollTo({ top: 680, behavior: "smooth" });
          }}
        >
          <div className="h-[120px] w-full bg-[#432444] rounded-t-[40px] pt-[34px] md:hidden">
            <p className="text-[45px] font-medium mb-[64px] leading-[54px] text-center">
              TALENT
            </p>
          </div>
        </div>
        <div className="flex flex-col mb-[60px] md:mb-[82px] relative mx-[-16px]">
          <h1 className="text-center text-[32px] md:text-[100px] md:leading-[128px] bg-[#A318A8] w-fit mx-auto font-bold md:px-6 px-2">
            DIGITAL AGENCY
          </h1>
          <div className="w-fit mx-auto relative">
            <h1 className="text-center text-[32px] md:text-[100px] md:leading-[128px] bg-[#A318A8] w-fit mx-auto font-bold md:px-6 px-2">
              MEETS EXCELLENCE .
            </h1>
            <div className="bg-[#A318A8] md:h-[12px] h-[6px] md:w-[64px] w-[20px] absolute md:bottom-[-12px] right-0"></div>
          </div>
        </div>
        <p className="text-[18px] text-center leading-[24px] md:leading-[35px] w-fit md:w-[745px] mx-auto mb-[150px]">
          Welcome to Incognito, a premier talent management agency in the UK!
          <br className="hidden md:block" /> We manage creators from all walks
          of life, enticing real and sustained engagement.
          <br />
          <br className="md:hidden" />
          Join us and thrive in your industry of choice!
        </p>
        <div
          className="w-fit relative mb-[40px] mx-auto"
          data-aos="services"
          data-aos-offset={isMobile ? "200" : "700"}
        >
          <p className="font-bold text-[40px] md:text-[140px] md:leading-[132px] text-center md:tracking-[24px] md:mt-[200px] mt-[240px] bg-[#A318A8] md:bg-transparent w-fit mx-auto px-[12px]">
            WHAT WE DO.
          </p>
          <div className="bg-[#A318A8] md:h-[12px] h-[6px] md:hidden w-[20px] absolute md:bottom-[-12px] right-0"></div>
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-3 gap-[29px] mb-[444px] !z-20 relative"
          style={{
            marginTop: `${
              !isMobile
                ? scrollY > 500
                  ? "0px"
                  : `${(-850 * (500 - scrollY)) / 500}px`
                : "0px"
            }`,
            // opacity: `${30 + (70 * scrollY) / 500}%`,
          }}
          // data-aos="fade-up"
          // data-aos-offset="100"
        >
          <div
            className="order-3 md:order-1"
            style={{
              marginTop: `${
                !isMobile
                  ? scrollY > 500
                    ? "0px"
                    : `${(120 * (500 - scrollY)) / 100}px`
                  : "0px"
              }`,
            }}
            data-aos="cards"
            data-aos-offset="300"
            data-aos-duration="1000"
          >
            <ServiceCard
              title="SECURITY"
              contents={[
                {
                  heading: "Safeguarding",
                  content:
                    "At Incognito we offer DMCA Takedown Services and robust Content Security Measures to safeguard you and your digital content.",
                },
                {
                  heading: "Mental Health",
                  content:
                    "We are keen to acknowledge the unique challenges that models may face in their careers. Our team is here to lend a listening ear and offer guidance when needed. We believe that open and supportive conversations can make the difference in mental well-being within our community.",
                },
              ]}
            />
          </div>
          <div
            className="order-1 md:order-2"
            style={{
              marginTop: `${
                !isMobile
                  ? scrollY > 500
                    ? "0px"
                    : `${(142 * (500 - scrollY)) / 100}px`
                  : "0px"
              }`,
            }}
            data-aos="cards"
            data-aos-offset="300"
            data-aos-duration="1000"
          >
            <ServiceCard
              title="TALENT"
              contents={[
                {
                  heading: "Marketing & Promotion",
                  content:
                    "We provide expert Social Media Management, underpinned by Data-driven Analytics and offer invaluable guidance on content to elevate your online presence.",
                },
                {
                  heading: "Community",
                  content:
                    "We specialise in Community Building & Maintenance, fostering connections and engagement within our community. Enhancing networking opportunities, promoting mutual support, sharing valuable insights, and advancing careers collectively!",
                },
              ]}
            />
          </div>
          <div
            className="order-2 md:order-3"
            style={{
              marginTop: `${
                !isMobile
                  ? scrollY > 500
                    ? "0px"
                    : `${(133 * (500 - scrollY)) / 100}px`
                  : "0px"
              }`,
            }}
            data-aos="cards"
            data-aos-offset="300"
            data-aos-duration="1000"
          >
            <ServiceCard
              title="REFINE"
              contents={[
                {
                  heading: "Model Development",
                  content:
                    "We offer comprehensive services encompassing Growth & Planning coupled with Financial Education & Budgeting to empower your career journey.",
                },
                {
                  heading: "Network",
                  content:
                    "We provide Industry Exposure and Networking services. Offering numerous benefits such as increased visibility, collaboration opportunities, and valuable industry connections to further your modelling career.",
                },
              ]}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-[84px]">
          <div className="md:w-[620px]">
            <div
              data-aos="testimonials"
              data-aos-offset={isMobile ? "100" : "700"}
            >
              <p className="font-bold text-[32px] md:text-[44px] md:tracking-[11px] text-center md:text-left bg-[#A318A8] md:bg-transparent w-fit mx-auto md:mx-0 px-2 md:px-0">
                OUR CLIENTS TRUST
              </p>
              <div className="w-fit mx-auto md:mx-0 mb-[40px] relative">
                <p className="font-bold text-[32px] md:text-[44px] md:tracking-[11px] text-center md:text-left bg-[#A318A8] md:bg-transparent w-fit px-2 md:px-0">
                  US IMPLICITLY.
                </p>
                <div className="bg-[#A318A8] md:h-[12px] h-[6px] md:hidden w-[20px] absolute md:bottom-[-12px] right-0"></div>
              </div>
            </div>

            <p className="text-center md:text-left">
              Encapsulating the confidence and peace of mind we bring to those
              we collaborate with. This trust is built on our consistent
              delivery of high quality work and open communication.
              <br />
              <br />
              It's not just what we do, but how we do it.
            </p>
          </div>
          <div>
            <Swiper
              autoplay={{
                delay: 7000,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              loop
              grabCursor={true}
              className="md:w-[736px] overflow-hidden"
              effect={"cards"}
              slidesPerView={1}
              modules={[Pagination, EffectCards, Autoplay]}
              pagination={{ clickable: true }}
            >
              {reviews.map((item) => (
                <SwiperSlide
                  className="flex justify-center items-center overflow-hidden bg-white rounded-[40px] border border-[#1C8472]"
                  key={item.name}
                >
                  <ReviewCard
                    name={item.name}
                    position={item.position}
                    avatar={item.avatar}
                    review={item.review}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="py-[444px] relative">
          <p className="text-center text-[45px] tracking-[4.5px] leading-[54px] mb-[82px]">
            Curious How We Do It?
          </p>
          <a href="https://ig.me/m/incognitomgt" target="_blank">
            <span className="rounded-full px-[56px] py-[20px] text-[20px] bg-gradient-to-r from-[#461A47] to-[#B441B8] block mx-auto w-fit">
              GET IN TOUCH
            </span>
          </a>
          <div
            className="hidden md:flex justify-end absolute right-0 bottom-[140px]"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            data-aos="fade-zoom-in"
            data-aos-offset="350"
          >
            <div className="flex items-center justify-end gap-[20px] w-fit duration-300 mr-[-100px]">
              <p className="text-[20px]">Back To Top</p>
              <img src="/assets/svg/back_to_top.svg" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
