const ReviewCard = ({
  review,
  avatar,
  name,
  position,
}: {
  review: string;
  avatar: string;
  name: string;
  position: string;
}) => {
  return (
    <>
      <div className="px-[24px] md:px-[68px] py-[24px] md:py-[48px] md:w-[736px] bg-[#1D1D1D] !overflow-hidden flex flex-col h-[480px] md:h-[378px]">
        <div className="mb-[42px]">
          <img src="/assets/svg/stars.svg" />
        </div>
        <p className="text-[18px] mb-[28px] leading-[22px]">{review}</p>
        <div className="flex gap-[20px] items-center mt-auto">
          <img className="w-[76px]" src={avatar} />
          <div>
            <p className="text-[24px] font-medium">{name}</p>
            <p>{position}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
