import { useEffect } from "react";
import Subitem from "./Subitem";
import { useScroll } from "hooks/useScroll";
import useWindowSize from "hooks/useWindowSize";

const ServiceCard = ({
  title,
  contents,
}: {
  title: string;
  contents: { heading: string; content: string }[];
}) => {
  const { scrollY } = useScroll();
  const { width, height } = useWindowSize();
  return (
    <>
      <div
        className={`md:bg-[#432444] border-2 border-[#432444] rounded-[40px] duration-300 pt-[34px] px-[40px] pb-[80px] h-full group ${
          scrollY < 500 ? `` : `hover:bg-transparent`
        }`}
        onClick={() =>
          width > 768 && window.scrollTo({ top: 800, behavior: "smooth" })
        }
      >
        <p className="text-[45px] font-medium mb-[64px] leading-[54px] text-center">
          {title}
        </p>
        <div
          className={`flex flex-col gap-[64px] ${
            scrollY < 500 ? `md:opacity-0` : `md:group-hover:opacity-100`
          }  md:opacity-0 duration-300`}
        >
          {contents.map((item) => (
            <Subitem heading={item.heading} content={item.content} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
