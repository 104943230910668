import { Link } from "react-router-dom";
import Dropdown from "../Dropdown";
import Instagram from "components/Icon/Instagram";
import { useScroll } from "hooks/useScroll";

const Header = () => {
  const { scrollY } = useScroll();

  return (
    <div
      className={`fixed w-screen left-0 top-0 z-50 bg-[#1D1D1D] duration-300 ease-in-out ${
        scrollY < 1 ? `translate-y-0` : `-translate-y-full`
      }`}
    >
      <div className="flex justify-between items-center h-[100px] md:h-[140px] px-[20px] md:px-[225px] max-w-[390px] md:max-w-full mx-auto">
        <Link className="hidden md:block" to={`/`}>
          <div className="flex gap-[20px] items-end">
            <img className="md:w-[72px] w-[40px]" src="/assets/logo.png" />
            <p className="text-[20px] tracking-[4px] leading-[20px] hidden md:block">
              INCOGNITO
            </p>
          </div>
        </Link>
        <div className="flex items-center md:gap-[56px] md:self-end flex-row-reverse md:flex-row justify-between w-full md:w-auto">
          <div className="hidden md:block">
            <Dropdown label="CONTACT">
              <a href="https://ig.me/m/incognitomgt" target="_blank">
                <div className="flex gap-[20px] mb-[12px] group">
                  <p className="group-hover:text-[#A318A8]">dm us on</p>
                  <Instagram className="fill-white group-hover:fill-[#a318a8]" />
                </div>
              </a>
              {/* <a href="https://twitter.com/Incognitomgt">
            <div className="flex gap-[20px] mb-[12px]">
              <p className="hover:text-[#A318A8]">dm us on</p>
              <img className="w-[24px]" src="/assets/svg/social/twitter.svg" />
            </div>
          </a> */}
            </Dropdown>
          </div>
          <div className="md:hidden">
            <a href="https://ig.me/m/incognitomgt" target="_blank">
              <div className="flex flex-col justify-center items-center ">
                <img src="/assets/svg/dm_us.svg" className="w-[40px]" />
                <p>DM US</p>
              </div>
            </a>
          </div>
          <a
            href="https://o4zedtj07wu.typeform.com/to/JumAXNe9"
            target="_blank"
          >
            <div className="px-[20px] py-[10px] md:px-[44px] md:py-[20px] rounded-[16px] md:rounded-[20px] border-[#A318A8] border-[1px] font-medium bg-[#A318A8] md:bg-transparent md:hover:bg-[#A318A8] text-[20px] leading-[24px]">
              WORK WITH US
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
